<template>
  <q-page class="">
    <q-tabs
        v-model="tab"
        inline-label
        class="bg-green text-white shadow-2"
      >
        <q-tab name="cities" icon="location_city" label="Villes" />
        <q-tab name="countries" icon="flag" label="Pays" />
        <q-tab name="nationalities" icon="person_pin" label="Nationalités" />
        <q-tab name="opcos" icon="help_outline" label="OPCOS" />
        <q-tab name="naf" icon="help_outline" label="NAF" />
        <q-tab name="idcc" icon="help_outline" label="IDCC" />
        <q-tab name="schools" icon="school" label="Écoles" />
        <q-tab name="formations" icon="history_edu" label="Formations" />
        <q-tab name="campus" icon="domain" label="Campus" />
    </q-tabs>

    <div class="main-content">
      <!-- Include the component here with tab value -->
      <p style="color: red;" v-if="tab === 'cities' || tab === 'nationalities' || tab === 'opcos' || tab === 'naf' || tab === 'idcc' || tab === 'formations'">La table n'existe pas encore.</p>
      <CountriesTable v-if="tab === 'countries'"/>
      <SchoolsTable v-if="tab === 'schools'"/>
      <CampusTable v-if="tab === 'campus'"/>
      <FormationsTable v-if="tab === 'formations'"/>
      <CitiesTable v-if="tab === 'cities'"/>
      <NationalitiesTable v-if="tab === 'nationalities'"/>
      <OpcosTable v-if="tab === 'opcos'"/>
      <NafTable v-if="tab === 'naf'"/>
      <IdccTable v-if="tab === 'idccTable'"/>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
import { ref } from 'vue'
import CountriesTable from '../components/CountriesTable.vue'
import SchoolsTable from '../components/SchoolsTable.vue'
import CampusTable from '../components/CampusTable.vue'
import FormationsTable from "@/components/FormationsTable";
import CitiesTable from "@/components/CitiesTable";
import NationalitiesTable from "@/components/NationalitiesTable";
import OpcosTable from "@/components/OpcosTable";
import NafTable from "@/components/NafTable";
import IdccTable from "@/components/IdccTable";

export default {
  name: 'Admin',
  components: {
    IdccTable,
    NafTable,
    OpcosTable,
    NationalitiesTable,
    CitiesTable,
    FormationsTable,
    CountriesTable,
    SchoolsTable,
    CampusTable,
  },
  setup () {
    // Default variables
    const tab = ref('countries')
    return {
      tab
    }
  },
}
</script>
