<template>
    <div class="main-content">
        <q-table
            :title="title"
            :rows="rows"
            :columns="columns"
            row-key="id"
            v-model:pagination="pagination"
            :loading="loading"
            :filter="filter"
            @request="onRequest"
            binary-state-sort
            >
            <template v-slot:top-right>
                <!-- Fixed size, need improvement to be more responsive -->
                <q-btn icon="add" @click="create()"></q-btn>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <q-select style="width: 200px;" outlined v-model="filter_option_selected" :options="filter_options" label="Column" />
                <q-input borderless dense debounce="300" class="q-ml-md" v-model="filter" placeholder="Search">
                <template v-slot:append>
                    <q-icon name="search" />
                </template>
                </q-input>
            </template>
            <template v-slot:body-cell-edit="props">
                <q-td :props="props">
                    <q-btn icon="edit" @click="edit(props.row)"></q-btn>
                </q-td>
            </template>
            <template v-slot:body-cell-remove="props">
                <q-td :props="props">
                    <q-btn icon="delete" style="color: red;" @click="remove(props.row)"></q-btn>
                </q-td>
            </template>
        </q-table>

        <q-dialog v-model="edit_value.state" persistent>
            <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center">
                    <q-avatar icon="edit" color="primary" text-color="white" />
                    &nbsp;&nbsp;&nbsp;<h4>Edit - {{ title }}</h4>
                </q-card-section>

                <q-card-section>
                    <q-input v-for="(value, key) in edit_value.row" v-bind:key="key" v-model="edit_value.row[key]" :label="key" />
                </q-card-section>

                <q-card-section v-if="edit_value.loading">
                    <q-spinner
                        color="primary"
                        size="3em"
                    />
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cancel" color="red" v-close-popup />
                    <q-btn flat label="Submit" color="primary" @click="editSubmit()" />
                </q-card-actions>
            </q-card>
        </q-dialog>

        <q-dialog v-model="create_value.state" persistent>
            <q-card style="width: 700px; max-width: 80vw;">
                <q-card-section class="row items-center">
                    <q-avatar icon="edit" color="primary" text-color="white" />
                    &nbsp;&nbsp;&nbsp;<h4>Create - {{ title }}</h4>
                </q-card-section>

                <q-card-section>
                    <q-input v-for="(value, key) in create_value.inputs" v-bind:key="key" v-model="create_value.inputs[key]" :label="key" />
                </q-card-section>

                <q-card-section v-if="create_value.loading">
                    <q-spinner
                        color="primary"
                        size="3em"
                    />
                </q-card-section>

                <q-card-actions align="right">
                    <q-btn flat label="Cancel" color="red" v-close-popup />
                    <q-btn flat label="Submit" color="primary" @click="createSubmit()" />
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<style>
</style>

<script>
import { ref, watch } from 'vue'
import { useQuery, useResult, useMutation, provideApolloClient } from '@vue/apollo-composable'
import { useQuasar } from 'quasar'
import { apolloClient } from '@/apolloClient.js'

export default {
    name: 'SchoolsTable',
    data () {
        return {
            edit_value: { state: false, loading: false, row: null, id: null },
            create_value: { state: false, loading: false, inputs: null },
        }
    },
    methods: {
        onRequest (query) {
            this.pagination = query.pagination
            if (query.filter !== "") {
                this.pagination.filters = {
                    [this.filter_option_selected]: {
                        "_ilike": `%${query.filter}%`
                    }
                }
            } else {
                this.pagination.filters = {}
            }

            this.variables = {
                startRow: this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage,
                count: this.pagination.rowsPerPage === 0 ? this.pagination.rowsNumber : this.pagination.rowsPerPage,
                orderBy: {
                    [this.pagination.sortBy]: this.pagination.descending ? 'desc' : 'asc'
                },
                filters: this.pagination.filters
            }

            this.variablesCount = {
                filters: this.pagination.filters
            }
        },
        edit (row) {
            this.edit_value.id = row.id
            if (row.created_at) delete row.created_at;
            if (row.updated_at) delete row.updated_at;
            delete row.id
            this.edit_value.row = row
            this.edit_value.state = true
        },
        async editSubmit () {
            // Loading
            this.edit_value.loading = true

            // Update
            const variables = this.edit_value.row
            variables.id = this.edit_value.id

            // Do graphql mutation
            const { mutate } = useMutation(require('@/gql/schools/UpdateSchools.gql'))
            const result = await mutate(variables)

            // Check errors
            if (result.data.update_schools_by_pk === null) {
                this.edit_value.loading = false
                this.edit_value.state = false
                this.quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: 'Update failed !',
                    position: 'top',
                    timeout: 5000
                })
            } else {
                // Close dialog
                this.edit_value.state = false
                this.edit_value.loading = false
                
                // Show a popup
                this.quasar.notify({
                        color: 'green',
                        textColor: 'white',
                        icon: 'done',
                        message: 'Updated succesfully !',
                        position: 'top',
                        timeout: 5000
                })
            }
        },
        create () {
            this.create_value.inputs = ref({
                name: '',
            })
            this.create_value.state = true
        },
        async createSubmit () {
            // Loading
            this.create_value.loading = true

            // Update
            const variables = this.create_value.inputs

            // Do graphql mutation
            const { mutate } = useMutation(require('@/gql/schools/InsertSchools.gql'))
            const result = await mutate(variables)

            // Check errors
            if (result.data.insert_schools.affected_rows !== 1) {
                this.create_value.loading = false
                this.create_value.state = false
                this.quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: 'Creation failed !',
                    position: 'top',
                    timeout: 5000
                })
            } else {
                // Close dialog
                this.create_value.state = false
                this.create_value.loading = false
                
                // Show a popup
                this.quasar.notify({
                        color: 'green',
                        textColor: 'white',
                        icon: 'done',
                        message: 'Created succesfully !',
                        position: 'top',
                        timeout: 5000
                })

                // Refresh table
                this.refetchCount(this.variablesCount)
                this.refetch(this.variables)
            }
        },
        async remove (row) {
            const { mutate } = useMutation(require('@/gql/schools/DeleteSchools.gql'))
            const result = await mutate({
                id: row.id
            })

            // Check errors
            if (result.data.delete_schools_by_pk.id !== row.id) {
                this.quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: 'Delete failed !',
                    position: 'top',
                    timeout: 5000
                })
            } else {
                // Show a popup
                this.quasar.notify({
                        color: 'green',
                        textColor: 'white',
                        icon: 'done',
                        message: 'Deleted succesfully !',
                        position: 'top',
                        timeout: 5000
                })

                // Refresh table
                this.refetchCount(this.variablesCount)
                this.refetch(this.variables)
            }
        }
    },
    setup () {
        const quasar = useQuasar()

        provideApolloClient(apolloClient)

        // Default variables
        const title = 'Écoles'
        const columns = [
            { name: 'ID', align: 'left', label: 'ID', field: 'id', sortable: false },
            { name: 'name', align: 'left', label: 'Nom', field: 'name', sortable: true },
            { name: 'edit', align: 'left', label: 'Edit', sortable: false },
            { name: 'remove', align: 'left', label: 'Delete', sortable: false },
        ]
        const filter_options = ['name']
        const filter_option_selected = ref('name')
        const filter = ref('')

        let pagination = ref({
            sortBy: 'name',
            descending: false,
            page: 1,
            rowsPerPage: 10,
            rowsNumber: 10,
            filters: {}
        })

        // Get schools count
        const variablesCount = ref({
            filters: pagination.value.filters
        })
        const { result: resultCount, refetch: refetchCount } = useQuery(require('@/gql/schools/GetSchoolsCount.gql'), variablesCount)

        const rowsNumber = useResult(resultCount, 10, data => data.schools_aggregate.aggregate.count)
        watch(rowsNumber, () => {
            pagination.value.rowsNumber = rowsNumber.value
        })

        // Get schools
        const variables = ref({
            startRow: (pagination.value.page - 1) * pagination.value.rowsPerPage,
            count: pagination.value.rowsPerPage === 0 ? pagination.value.rowsNumber : pagination.value.rowsPerPage,
            orderBy: {
                [pagination.value.sortBy]: pagination.value.descending ? 'desc' : 'asc'
            },
            // Filter example:
            // filters: {"first_name": {"_ilike": "Maxence"}}
            filters: pagination.value.filters
        })
        const { result, loading, onError, refetch } = useQuery(require('@/gql/schools/GetSchools.gql'), variables)

        onError(error => {
            console.log(error)
            quasar.notify({
                    color: 'red',
                    textColor: 'white',
                    icon: 'error',
                    message: error.message,
                    position: 'top',
                    timeout: 5000
            })
        })

        const rows = useResult(result, [], data => {
            return data.schools.map(row => ({
                id: row.id,
                name: row.name,
            }))
        })

        return {
            title,
            columns,
            filter_options,
            filter_option_selected,
            filter,
            pagination,
            loading,
            rows,
            variables,
            variablesCount,
            quasar,
            refetch,
            refetchCount
        }
    },
}
</script>
